import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

let ls = new SecureLS({ isCompression: false });

export default createStore({
  state: {
    token: '',
    user: null,
    adminMenu: null
  },
  getters: {
    isLoggedIn(state) {
      return state.user?.id != null;
    },
    getUser(state) {
      return state.user;
    },
    getUserId(state) {
      return state.user.id;
    },
    getAccessToken(state) {
      return state.token;
    },
    getRefreshToken(state) {
      return state.refreshToken;
    },
    getAdminMenu(state) {
      return state.adminMenu;
    },
    checkLoggedUserIsExpired() {

    }
  },
  mutations: {
    setUser(state, data) {
      state.user = {
        id: data.userData.id,
        name: data.userData.name,
        surName: data.userData.surName,
        email: data.userData.email,
        roles: data.userData.roles
      };
    },
    setToken(state, data) {
      state.token = data;
    },
    setRefreshToken(state, token) {
      state.refreshToken = token;
    },
    setAdminMenu(state, data) {
      state.adminMenu = data;
    },
    logout(state) {
      state.user = null;
      state.token = '';
      state.refreshToken = '';
      state.adminMenu = null;
    }
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
})
