<template>
        <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <div class="col-12 mt-5 xl:mt-0 text-center">
               
            </div>
            <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <div class="text-center mb-5">
                        <div class="text-900 text-3xl font-medium mb-3">Çıkış işleminiz başarı ile yapıldı</div>
                        <span class="text-600 font-medium">Tekrar giriş yapmak isterseniz lütfen <router-link to="login"> buraya tıklayın</router-link></span>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/store";
export default {
    name : "Logout",
    created() {
        store.commit("logout");
    },
}
</script>