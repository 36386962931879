import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@/store';
import Dashboard from '@/components/Dashboard.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Dashboard,
    meta: { authRequired: true, expectedRoles: ['Admin'] }
  },
  {
    path: '/ManagerUsers/List',
    name: 'managerUserList',
    component: () => import('@/pages/managerUsers/list.vue'),
    meta: { authRequired: true, expectedRoles: ['Admin'] }
  },
  {
    path: '/ManagerUsers/Add',
    name: 'managerUserAdd',
    component: () => import('@/pages/managerUsers/add.vue'),
    meta: { authRequired: true, expectedRoles: ['Admin'] }
  },
  {
    path: '/ManagerUsers/Edit/:managerUserId',
    name: 'managerUserEdit',
    component: () => import('@/pages/managerUsers/edit.vue'),
    meta: { authRequired: true, expectedRoles: ['Admin'] }
  },
  {
    path: '/Resources/List',
    name: 'resourceList',
    component: () => import('@/pages/resources/list.vue'),
    meta: { authRequired: true, expectedRoles: ['Admin'] }
  },
  {
    path: '/Resources/Add',
    name: 'resourceAdd',
    component: () => import('@/pages/resources/add.vue'),
    meta: { authRequired: true, expectedRoles: ['Admin'] }
  },
  {
    path: '/Resources/Edit/:resourceId',
    name: 'resourceEdit',
    component: () => import('@/pages/resources/edit.vue'),
    meta: { authRequired: true, expectedRoles: ['Admin'] }
  },
  {
    path: '/ResourceDatas/List',
    name: 'resourceDataList',
    component: () => import('@/pages/resourceDatas/list.vue'),
    meta: { authRequired: true, expectedRoles: ['Admin'] }
  },
  {
    path: '/ResourceDatas/List/:resourceId',
    name: 'resourceDataListWithResourceId',
    component: () => import('@/pages/resourceDatas/list.vue'),
    meta: { authRequired: true, expectedRoles: ['Admin'] }
  },
  {
    path: '/ResourceDatas/Add',
    name: 'resourceDataAdd',
    component: () => import('@/pages/resourceDatas/add.vue'),
    meta: { authRequired: true, expectedRoles: ['Admin'] }
  },
  {
    path: '/ResourceDatas/Edit/:resourceDataId',
    name: 'resourceDataEdit',
    component: () => import('@/pages/resourceDatas/edit.vue'),
    meta: { authRequired: true, expectedRoles: ['Admin'] }
  },
  {
    path: '/PdfFile/Add',
    name: 'pdfFileAdd',
    component: () => import('@/pages/pdfFile/add.vue'),
    meta: { authRequired: true, expectedRoles: ['Admin'] }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/Login.vue')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/pages/Error.vue')
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: () => import('@/pages/NotFound.vue')
  },
  {
    path: '/access',
    name: 'access',
    component: () => import('@/pages/Access.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/pages/Logout.vue')
  },

];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const userIsLoggedIn = await store.getters.isLoggedIn;

  let userRoleValid = true;

  if (userIsLoggedIn) {
    const userRoles = await store.getters.getUser.roles;
    const expectedRoles = to.meta.expectedRoles;

    if (expectedRoles != null) {
      let requiredRoles = expectedRoles.filter(
        (u) => !userRoles.map((r) => r).includes(u)
      );

      if (requiredRoles.length > 0) {
        userRoleValid = false;
      }
    }
  }


  if (to.meta.authRequired && !userIsLoggedIn) {
    next('/login')
  }
  else {
    if (!userRoleValid) {
      next("/access");
    }
    else {
      next();
    }
  }
});

export default router;
