<template>
<div>

</div>
	
</template>

<script>
	export default {
		name: "AppFooter"
	}
</script>

<style scoped>

</style>
