<template>
    <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <div class="col-12 mt-5 xl:mt-0 text-center">
                <img :src="'layout/images/logo.png'" alt="Öz Metal" class="mb-5" style="width:169px; height:24px;">
            </div>
            <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <div class="text-center mb-5">
                        <div class="text-900 text-3xl font-medium mb-3">Yönetim Paneline Hoşgeldiniz</div>
                        <span class="text-600 font-medium">Giriş yapmak için kullanıcı bilgilerinizi kullanın</span>
                    </div>
                
                    <div class="w-full md:w-10 mx-auto">
                        <label for="email" class="block text-900 text-xl font-medium mb-2">E-Posta Adresiniz</label>
                        <InputText id="email" v-model="loginForm.email" type="text" class="w-full mb-3" placeholder="Eposta" style="padding:1rem;" />
                
                        <label for="password" class="block text-900 font-medium text-xl mb-2">Şifreniz</label>
                        <Password id="password" v-model="loginForm.password" placeholder="Şifre" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem"></Password>
                
                        <div class="flex align-items-center justify-content-between mb-5">
                                                   </div>
                        <Button label="Giriş Yap" class="w-full p-3 text-xl" @click="login"></button>
                        <Message :severity="message.type" v-if="message.show">{{message.content}}</Message>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/store";
import AuthenticationService from "@/services/authenticationService";


export default {

    _authService : null,

    created() {

        this._authService = new AuthenticationService();
    },
    data() {
        return {
            message: {
                type:'info',
                content:'',
                show:false
            },
            loginForm: {
            email: '',
            password: ''
            }
        }
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        }
    },
    methods: {
        async login() {
          const response = await this._authService.loginWithEmailAndPassword(this.loginForm.email, this.loginForm.password);
          if(response.isSuccess)
            {
                this.message.show = true;
                this.message.content = 'Kullanıcı adı ve şifreniz doğru.'
                this.message.type = 'success';

              store.commit("setUser", {userData: response.data});
              store.commit("setToken", response.data.token);
              store.commit("setRefreshToken", response.data.refreshToken);
                setTimeout(() => {  
                    this.message.show = false;
                    this.$router.push({name:'home'});
                 }, 2000);
            }
            else
            {
                this.message.content = response.message;
                this.message.show = true;
                this.message.type ='error';
            }
        }
    }
}
</script>
