<template>
	<div class="layout-topbar">

			<button class="p-link layout-menu-button layout-topbar-icon" @click="onMenuButtonClick">
				<i class="pi pi-bars"></i>
			</button>

			<router-link to="/" class="p-link layout-topbar-logo">
				<img id="topbar-logo" src="layout/images/logo_white.png" alt="Öz Metal"/>
			</router-link>

	

			<ul class="topbar-menu">
				<li :class="['user-profile', {'active-topmenuitem fadeInDown': activeTopbarItem === 'profile'}]">
					<button class="p-link" v-if="!this.inlineUser"
							@click="$emit('topbar-item-click',{originalEvent:$event,item:'profile'})">
						<img src="layout/images/avatar.png" alt="Öz Metal"/>
						<div class="layout-profile-userinfo">
							<span class="layout-profile-name">
                {{ userFullName}}
              </span>
							<span class="layout-profile-role">Administrator</span>
						</div>
					</button>
					<transition name="layout-submenu-container">
						<ul class="fadeInDown" v-show="activeTopbarItem === 'profile'">
							<li role="menuitem">
							<a href="#/ManagerUsers/Edit/1">
								<button class="p-link">
									<i class="pi pi-fw pi-user"></i>
									<span>Profil</span>
								</button>
							</a>
							</li>
							<li role="menuitem">
								<a href="#/logout">
								<button class="p-link">
									<i class="pi pi-fw pi-cog"></i>
									<span>Çıkış</span>
								</button>
								</a>
							</li>
					
						</ul>
					</transition>
				</li>

			</ul>
	</div>
</template>

<script>
	export default {
		emits: ['menubutton-click', 'topbar-menubutton-click', 'topbar-item-click',],
		props: {
			topbarMenuActive: Boolean,
			activeTopbarItem: String,
			inlineUser: Boolean,
		},
    mounted() {
      let user = this.$store.getters.getUser;
      console.log(user);
      this.userFullName = user.name + " " + user.surName;
    },

    methods: {
			onMenuButtonClick(event) {
				this.$emit('menubutton-click', event);
			},
			onTopbarMenuButtonClick(event) {
				this.$emit('topbar-menubutton-click', event);
			},
			onRightMenuButtonClick(event) {
				this.$emit('right-menubutton-click', event);
			}
		},
    data() {
      return {
        userFullName:"",
      }
    }
	}
</script>
