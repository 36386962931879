<template>
	<div></div>
</template>

<script>
	export default {
		emits: ['rightmenu-click'],
		props: {
			rightPanelMenuActive: Boolean
		},
		methods: {
			onRightMenuClick(event){
				this.$emit('rightmenu-click', event);
			}
		},
		computed: {
			panelClass(){
				return ['layout-right-panel', {
					'layout-right-panel-active': this.rightPanelMenuActive
				}]
			}
		},
		name: "AppRightMenu"
	}
</script>

<style scoped>

</style>
