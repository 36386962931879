<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Hoşgeldiniz...</h5>
        <p>
          
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>