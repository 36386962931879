<template>
    <div>

    </div>
</template>

<script>
export default {
    emits: ['layout-change', 'menu-color-change', 'profile-mode-change', 'orientation-change', 'topbar-color-change', 'theme-change'],
    props: {
        layoutMode: {
            type: String,
            default: null
        },
        lightMenu: {
            type: Boolean,
            default: null
        },
        inlineUser: {
            type: Boolean,
            default: null
        },
        isRTL: {
            type: Boolean,
            default: null
        },
        topbarColor: {
            type: String,
            default: null
        },
        topbarColors: {
            type: Array,
            default: null
        },
        theme: {
            type: String,
            default: null
        },
        themes: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            active: false,
            d_layoutMode: this.layoutMode,
            d_lightMenu: this.lightMenu,
            d_inlineUser: this.inlineUser,
            d_isRTL: this.isRTL
        }
    },
    watch: {
        $route() {
            if (this.active) {
                this.active = false;
                this.unbindOutsideClickListener();
            }
        },
        layoutMode(newValue) {
            this.d_layoutMode = newValue;
        },
        lightMenu(newValue) {
            this.d_lightMenu = newValue;
        },
        inlineUser(newValue) {
            this.d_inlineUser = newValue;
        },
        isRTL(newValue) {
            this.d_isRTL = newValue;
        }
    },
    outsideClickListener: null,
    methods: {
        toggleConfigurator(event) {
            this.active = !this.active;
            event.preventDefault();

            if (this.active)
                this.bindOutsideClickListener();
            else
                this.unbindOutsideClickListener();
        },
        hideConfigurator(event) {
            this.active = false;
            this.unbindOutsideClickListener();
            event.preventDefault();
        },
        changeInputStyle(value) {
            this.$primevue.config.inputStyle = value;
        },
        changeRipple(value) {
            this.$primevue.config.ripple = value;
        },
        changeLayout(event, layoutMode) {
            this.$emit('layout-change', layoutMode);
            event.preventDefault();
        },
        changeMenuColor(event, menuColor) {
            this.$emit('menu-color-change', menuColor);
            event.preventDefault();
        },
        changeProfileMode(event, profileMode) {
            this.$emit('profile-mode-change', profileMode);
            event.preventDefault();
        },
        changeOrientation(event, orientation) {
            this.$emit('orientation-change', orientation);
            event.preventDefault();
        },
        changeTopbarColor(event, topbarColor, logo) {
            this.$emit('topbar-color-change', topbarColor, logo);
            event.preventDefault();
        },
        changeTheme(event, theme) {
            this.$emit('theme-change', theme);
            event.preventDefault();
        },
        bindOutsideClickListener() {
            if (!this.outsideClickListener) {
                this.outsideClickListener = (event) => {
                    if (this.active && this.isOutsideClicked(event)) {
                        this.active = false;
                    }
                };
                document.addEventListener('click', this.outsideClickListener);
            }
        },
        unbindOutsideClickListener() {
            if (this.outsideClickListener) {
                document.removeEventListener('click', this.outsideClickListener);
                this.outsideClickListener = null;
            }
        },
        isOutsideClicked(event) {
            return !(this.$el.isSameNode(event.target) || this.$el.contains(event.target));
        }
    },
    computed: {
        containerClass() {
            return ['layout-config', {'layout-config-active': this.active}];
        },
        rippleActive() {
            return this.$primevue.config.ripple;
        },
        inputStyle() {
            return this.$primevue.config.inputStyle;
        }
    }
}
</script>
